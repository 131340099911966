@tailwind base;

@tailwind components;

@tailwind utilities;

/* index.css */

/* Customize scrollbar */
/* Thin scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fbbf24; /* Yellow-400 */
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f59e0b; /* Yellow-500 */
}

